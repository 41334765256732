<template>
    <div class="mainPage">
        <div class="mainPageContent">
            <div class="contentPanel">
                <div class="about">
                    <h1>This is an about page</h1>
                </div>
                <div class="textCenter info1">
                    <div>Startup Parameters:</div>
                    <div class="textnormal">
                        {{ startupParameters }}
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    export default {
        name: "Support",

        data: () => ({
            startupParameters: localStorage.getItem("deviceID"),

        })
    }
</script>